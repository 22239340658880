import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  selectIsLocalVideoEnabled,
  selectLocalPeer,
  selectVideoTrackByID,
  useAVToggle,
  useHMSStore,
  usePreviewJoin,
} from "@100mslive/react-sdk";
import { SettingsIcon } from "@100mslive/react-icons";
import {
  Avatar,
  Flex,
  flexCenter,
  Loading,
  styled,
  StyledVideoTile,
  Text,
  textEllipsis,
  useBorderAudioLevel,
  useTheme,
  Video,
} from "@100mslive/roomkit-react";
import IconButton from "../../IconButton";
import { AudioVideoToggle } from "../AudioVideoToggle";
import TileConnection from "../Connection/TileConnection";
import SettingsModal from "../Settings/SettingsModal";
import PreviewName from "./PreviewName";
import { useUISettings } from "../AppData/useUISettings";
import {
  defaultPreviewPreference,
  UserPreferencesKeys,
  useUserPreferences,
} from "../hooks/useUserPreferences";
import { UI_SETTINGS } from "../../common/constants";
import * as Sentry from "@sentry/react";
import { ScreeningContext } from '../../context/ScreeningContext';
import CustomSettingIcon from "../../icons/CustomSettingIcon";
import { ConnectionIndicator } from "../Connection/ConnectionIndicator";

const PreviewJoin = ({
  token,
  onJoin,
  env,
  skipPreview,
  initialName,
  asRole,
}) => {
  const [previewPreference, setPreviewPreference] = useUserPreferences(
    UserPreferencesKeys.PREVIEW,
    defaultPreviewPreference
  );
  const { screeningData } = React.useContext(ScreeningContext);
  const [name, setName] = useState(screeningData.developerName);
  const { isLocalAudioEnabled, isLocalVideoEnabled } = useAVToggle();
  const [previewError, setPreviewError] = useState(false);
  const { enableJoin, preview, join } = usePreviewJoin({
    name,
    token,
    initEndpoint: env ? `https://${env}-init.100ms.live/init` : undefined,
    initialSettings: {
      isAudioMuted: skipPreview || previewPreference.isAudioMuted,
      isVideoMuted: skipPreview || previewPreference.isVideoMuted,
      speakerAutoSelectionBlacklist: ["Yeti Stereo Microphone"],
    },
    captureNetworkQualityInPreview: true,
    handleError: (_, method) => {
      if (method === "preview") {
        setPreviewError(true);
      }
    },
    asRole,
  });

  const savePreferenceAndJoin = useCallback(() => {
    setPreviewPreference({
      name,
      isAudioMuted: !isLocalAudioEnabled,
      isVideoMuted: !isLocalVideoEnabled,
    });
    join();
    onJoin && onJoin();
  }, [
    join,
    isLocalAudioEnabled,
    isLocalVideoEnabled,
    name,
    setPreviewPreference,
    onJoin,
  ]);

  useEffect(() => {
    if (screeningData.developerName && screeningData.candidateId) {
      Sentry.setUser({
        id: screeningData.candidateId.toString(),
        username: screeningData.developerName,
        segment: "score-interviewee",
      });
    }
  }, [screeningData.developerName]);

  useEffect(() => {
    if (token) {
      if (skipPreview) {
        savePreferenceAndJoin();
      } else {
        preview();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, skipPreview]);  
  return (
    <Container>
      <Flex
        align="center"
        css={{
          position: "relative",
          flexDirection: "column",
          height: "350px",
          width: "100%",
          "@media (min-width: 1536px)": {
            height: "550px",
          },
          "@sm": {
            height: "auto",
          }
        }}
      >
        <PreviewTile name={name} error={previewError} />
        <PreviewControls
          enableJoin={enableJoin}
          savePreferenceAndJoin={savePreferenceAndJoin}
        />
      </Flex>
    </Container>
  );
};

const Container = styled("div", {
  width: "100%",
  ...flexCenter,
  flexDirection: "flex",
  "@sm": {
    width: "100%"
  }
});

export const PreviewTile = ({ name, error }) => {
  const localPeer = useHMSStore(selectLocalPeer);
  const borderAudioRef = useBorderAudioLevel(localPeer?.audioTrack);
  const isVideoOn = useHMSStore(selectIsLocalVideoEnabled);
  const mirrorLocalVideo = useUISettings(UI_SETTINGS.mirrorLocalVideo);
  const trackSelector = selectVideoTrackByID(localPeer?.videoTrack);
  const track = useHMSStore(trackSelector);

  const {
    aspectRatio: { width, height },
  } = useTheme();
  return (
    <StyledVideoTile.Container
      css={{
        bg: "$surface_default",
        aspectRatio: width / height,
        width: "min(100%, 95%)",
        height: "min(100vh, 100vh)",
        "@sm": {
          width: "min(360px, 100%)",
          height: "min(240px, 100vh)",
          maxWidth: "100%",
        },
      }}
      ref={borderAudioRef}
    >
      {localPeer ? (
        <>
          <Video
            mirror={track?.facingMode !== "environment" && mirrorLocalVideo}
            trackId={localPeer.videoTrack}
            data-testid="preview_tile"
          />
          {!isVideoOn ? (
            <StyledVideoTile.AvatarContainer>
              <Avatar name={name} data-testid="preview_avatar_tile" />
              <Flex 
                css={{
                  width: "100%",
                  gap: "$3"
                }}
                align="center" justify="center"
              >
                <Text css={{ ...textEllipsis("75%"), fontSize: "$sm" }}>
                  {name + " (You)"}                  
                </Text>             
                <ConnectionIndicator isTile peerId={localPeer.id} /> 
              </Flex>
            </StyledVideoTile.AvatarContainer>
          ) : null}
        </>
      ) : !error ? (
        <Loading size={100} />
      ) : null}
    </StyledVideoTile.Container>
  );
};

export const PreviewControls = () => {
  return (
    <Flex
      css={{
        position: "absolute", 
        bottom: "$10",
        left: "50%",
        transform: "translateX(-50%)",
        gap: "$6",
      }}
    >
      <Flex css={{ gap: "$6" }}>
        <AudioVideoToggle compact />
        <PreviewSettings />
      </Flex>
    </Flex>
  );
};

const PreviewSettings = React.memo(() => {
  const [open, setOpen] = useState(false);
  return (
    <Fragment>
      <IconButton
        css={{
          width: "50px",
          height: "50px",
          "&:hover": {
            backgroundColor: "$transparentBg !important"
          },
        }}
        data-testid="preview_setting_btn"
        onClick={() => setOpen(value => !value)}
      >
        <CustomSettingIcon />
      </IconButton>
      {open && <SettingsModal open={open} onOpenChange={setOpen} />}
    </Fragment>
  );
});

export default PreviewJoin;