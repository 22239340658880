// MyCustomIcon.js
import React from "react";

const BlueCheck = props => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00004 15.1111C11.6819 15.1111 14.6667 12.1264 14.6667 8.44447C14.6667 4.76257 11.6819 1.7778 8.00004 1.7778C4.31814 1.7778 1.33337 4.76257 1.33337 8.44447C1.33337 12.1264 4.31814 15.1111 8.00004 15.1111Z"
      stroke="#5186ED"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.9984 6.48987L6.99836 10.4899L5.18018 8.67169"
      stroke="#5186ED"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default BlueCheck;
