import React from 'react';
import { Box, Button, Flex, Text } from "@100mslive/roomkit-react";

const Modal = ({ isOpen, onClose, title, children }) => {
  if (!isOpen) return null;

  return (
    <Flex
      css={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000,
      }}
    >
      <Box
        css={{
          backgroundColor: 'white',
          padding: '50px',
          borderRadius: '8px',
          width: '600px',
          textAlign: 'center', // Centrar el texto
        }}
      >
        <Text css={{ fontSize: '18px', fontWeight: 'bold', color:'black', textAlign:'center' }}>{title}</Text>
        <Box css={{ marginTop: '20px' }}>{children}</Box>
      </Box>
    </Flex>
  );
};

export default Modal;